<template>
  <div class="social-share">
    <ul class="social-share__list">
      <li
        v-for="(link, key) in socialLinks"
        :key="key"
        class="social-share__item"
      >
        <a
          :href="link.href"
          target="_blank"
          rel="noopener nofollow"
          :class="`social-share__link social-share__link--${key} link link--unstyled`"
        >
          <icon-component
            :name="link.icon"
            :title="link.title"
            class="social-share__icon"
          />
        </a>
      </li>
      <li class="social-share__item">
        <button
          class="social-share__link social-share__link--print text-button link link--unstyled"
          @click="handlePrint"
        >
          <icon-component name="printer" title="print page" class="social-share__icon" />
        </button>
      </li>
      <li class="social-share__item">
        <bookmark-button :bookmarkable="post" />
      </li>
    </ul>
    <ul class="social-share__list">
      <li
        v-if="showCommentLink"
        class="social-share__item"
      >
        <a
          v-if="post.type !== 'page'"
          href="#comment-thread"
          class="social-share__link social-share__link--comment text-button link link--unstyled"
          @click="goToComments"
        >
          <icon-component name="text-bubble" title="comment" class="social-share__icon" />
          <span class="social-share__link-number">
            {{ totalComments }}
          </span>
        </a>
      </li>
      <li class="social-share__item">
        <reaction-menu
          v-if="post.type !== 'page' && isOriginalSite"
          :id="post.id"
          content-type="post"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ReactionMenu from '@/components/Reactions/ReactionMenu.vue';
import BookmarkButton from '@/components/Bookmark/BookmarkButton.vue';
import scrollToMixin from '@/mixins/scroll-to-mixin';

export default {
  name: 'SocialShare',
  components: {
    ReactionMenu,
    BookmarkButton,
  },
  mixins: [scrollToMixin],
  props: {
    commentCount: {
      type: Number,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      socialLinks: {
        facebook: {
          title: 'Share to Facebook',
          icon: 'facebook-outlined',
          href: '',
        },
        twitter: {
          title: 'Share to Twitter',
          icon: 'twitter-outlined',
          href: '',
        },
      },
      timer: null,
    };
  },
  computed: {
    totalComments() {
      if (!this.commentCount) {
        return 0;
      }
      return this.commentCount;
    },
    isOriginalSite() {
      return !this.post.originalSite || this.post.originalSite === this.$site.prefix;
    },
    showCommentLink() {
      return this.$store.getters.siteHasFeature('Comments') && this.post.commentStatus !== 'closed' && this.isOriginalSite;
    },
  },
  mounted() {
    const currentUrl = encodeURIComponent(window.location.href);
    this.socialLinks.facebook.href = `https://www.facebook.com/sharer.php?u=${currentUrl}`;
    this.socialLinks.twitter.href = `https://twitter.com/share?url=${currentUrl}`;
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    goToComments(event) {
      event.preventDefault();
      this.$emit('goToComments');
      this.scrollTo('#comment-thread');
    },
    handlePrint() {
      this.$emit('openReferences');
      this.timer = window.setTimeout(() => {
        window.print();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_social-share';
</style>

<docs>
</docs>
