<template>
  <div class="comment-replies">
    <button
      v-if="showTruncatedReplies"
      class="comment-replies__toggle text-button"
      :aria-expanded="isOpened ? 'true' : 'false'"
      aria-controls="comment-replies-replies"
      @click="showPreviousReplies()"
    >
      {{ viewRepliesButtonText }}
      <icon-component
        name="caret"
        title="caret icon"
        class="comment-replies__toggle-icon icon--small"
      />
    </button>
    <ol
      v-if="showTruncatedReplies"
      id="comment-replies-replies"
      class="comment-replies__replies"
    >
      <li
        v-for="comment in mostRecentReplies"
        :key="`recent-${comment.id}`"
        class="comment-replies__reply"
      >
        <comment-component
          :comment="comment"
          :post="post"
        />
      </li>
    </ol>
    <ol
      v-else
      id="comment-replies-replies"
      class="comment-replies__replies"
    >
      <li
        v-for="comment in comments"
        :key="`reply-${comment.id}`"
        class="comment-replies__reply"
      >
        <comment-component
          :comment="comment"
          :post="post"
        />
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'CommentReplies',
  components: {
    CommentComponent: () => import('@/components/Comments/CommentComponent.vue'),
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mostRecentReplies: [],
      isOpened: false,
      recentLimit: 2,
    };
  },
  computed: {
    showTruncatedReplies() {
      return this.comments.length > this.recentLimit && !this.isOpened;
    },
    viewRepliesButtonText() {
      return `View ${this.comments.length - this.mostRecentReplies.length} previous replies`;
    },
  },
  created() {
    if (this.comments.length > this.recentLimit) {
      this.mostRecentReplies = this.comments.slice(
        this.comments.length - this.recentLimit,
        this.comments.length,
      );
    }
  },
  methods: {
    showPreviousReplies() {
      this.isOpened = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_comment-replies';
</style>

<docs>
A list of Comment Replies which belong to a comment.
If there are more than 2 replies, it is truncated
and will show a CTA to show previous replies after
the most recent two

</docs>
