<template>
  <ul class="reaction-grid" data-test-id="reaction-grid">
    <reaction-option
      v-for="reactionType in Object.keys(reactions)"
      :id="reactions[reactionType].id"
      :key="`${reactions[reactionType].id}-reaction-grid`"
      :type="reactionType"
      :count="reactions[reactionType].count"
      :is-selected="reactionType == selectedReaction"
      @remove-reaction="removeReaction(reactionType)"
      @select-reaction="selectReaction(reactionType)"
    />
  </ul>
</template>

<script>
import ReactionOption from '@/components/Reactions/ReactionOption.vue';
import reactionMixin from '@/mixins/reaction-mixin';

export default {
  name: 'ReactionGrid',
  components: {
    ReactionOption,
  },
  mixins: [reactionMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_reaction-grid';
</style>

<docs>

ReactionGrid displays a grid of reactions usually under a post.
It uses the reactionMixin

</docs>
