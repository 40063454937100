<template>
  <div class="sidebar-ads">
    <div
      v-for="(adSection, index) in adSections"
      :key="index"
      class="sidebar-ads__placeholder"
    >
      <ad-slot sticky v-bind="adSection.options" />
    </div>
  </div>
</template>

<script>
import AdSlot from '@/components/Ads/AdSlot.vue';

export default {
  name: 'SidebarAds',
  components: {
    AdSlot,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  computed: {
    adSections() {
      return this.sections.filter((section) => {
        const adQualifiesForSidebar = section.options.dynamic || section.options.displayInSidebar;
        return section.type === 'AdSlot' && adQualifiesForSidebar;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_sidebar-ads';
</style>

<docs>
This component filters Dynamic Ads from CREAM content and prepares them for rendering in the
app sidebar. Ads that are explicitly placed in content with shortcodes are deliberately excluded.

The component should be used in conjunction with named slots in the page layout.
</docs>
