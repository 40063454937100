/**
 * This mixin is responsible for handling CSS and JS added to the site header and footer
 * via WordPress admin.
 */
export default {
  watch: {
    post(to) {
      if (to && Object.keys(to).includes('footContent')) {
        this.processFootContent();
      }
    },
  },
  computed: {
    headTags() {
      /* API converts raw head text into an object vue-meta can consume to inject tags. For example:
        {
          script: [{src: "path/to/src.js", async: true, defer: true}],
          link: [],
          style: [{cssText: "p { color: red;"}]
        }
      */
      return this.post.headTagAttrs || {};
    },
  },
  methods: {
    createScriptTag(content) {
      const footScript = document.createElement('script');
      const inlineScript = document.createTextNode(content);
      footScript.appendChild(inlineScript);

      return footScript;
    },
    appendDocumentWriteContent(script) {
      const tmp = document.createElement('div');
      tmp.innerHTML = script;
      const injection = tmp.firstChild.innerText.replace('document.write(\'', '').replace('\')', '');
      this.$refs.content.insertAdjacentHTML('beforeend', injection);
    },
    appendFootScript(script) {
      if (/document.write/.test(script)) {
        this.appendDocumentWriteContent(script);
        return;
      }
      const nodes = document.createRange().createContextualFragment(script);
      this.$refs.footScriptContainer.appendChild(nodes);
    },
    appendRawJs(rawJs) {
      const scriptTag = this.createScriptTag(rawJs);
      this.$refs.footScriptContainer.append(scriptTag);
    },
    processFootContent() {
      // eslint-disable-next-line no-useless-escape
      const pattern = new RegExp(/<script.*[<\/script>|\/>]/, 'gs');
      const scriptTags = this.post.footContent.match(pattern);
      const rawJS = this.post.footContent.replace(pattern, '').trim();

      this.$nextTick(() => {
        if (scriptTags) {
          scriptTags.forEach((script) => {
            this.appendFootScript(script);
          });
        }
        if (rawJS.length > 0) {
          this.appendRawJs(rawJS);
        }
      });
    },
  },
};
