<template>
  <div class="hero">
    <div class="hero__image-container">
      <picture>
        <source
          v-if="heroMedia.urlMedium"
          type="image/webp"
          media="(max-width: 518px)"
          :srcset="toWebp(heroMedia.urlMedium)"
        >
        <source
          v-if="heroMedia.urlMedium"
          type="image/jpeg"
          media="(max-width: 518px)"
          :srcset="heroMedia.urlMedium"
        >
        <source
          type="image/webp"
          media="(min-width: 519px)"
          :srcset="toWebp(desktopImage)"
        >
        <img
          :src="desktopImage"
          :alt="imageAlt"
          class="hero__image"
          data-test-id="hero-image"
          @error="handleErrorOnImageSource"
        >
      </picture>
    </div>
    <p
      v-if="heroMedia.huIllustratorName"
      class="hero__illustrator-byline"
      data-test-id="hero-illustrator-byline"
    >Illustrated by {{ heroMedia.huIllustratorName }}
    </p>
  </div>
</template>

<script>
import { toWebp, handleErrorOnImageSource } from '@/utils/utils';

export default {
  name: 'HeroComponent',
  props: {
    heroMedia: {
      type: Object,
      required: true,
    },
  },
  computed: {
    acceptsWebp() {
      return this.$store.state.acceptsWebp;
    },
    desktopImage() {
      return this.heroMedia.urlLarge || this.heroMedia.url;
    },
    imageAlt() {
      return this.heroMedia.alt || '';
    },
    srcSetForImagePreload() {
      let srcSet = '';
      if (this.heroMedia.urlMedium) srcSet += `${this.acceptsWebp ? this.toWebp(this.heroMedia.urlMedium) : this.heroMedia.urlMedium} 1036w, `;
      srcSet += `${this.acceptsWebp ? this.toWebp(this.desktopImage) : this.desktopImage} 2160w`;

      return srcSet;
    },
  },
  methods: {
    toWebp,
    handleErrorOnImageSource,
  },
  metaInfo() {
    return {
      link: [{
        vmid: 'heroPreload',
        rel: 'preload',
        as: 'image',
        href: this.acceptsWebp ? this.toWebp(this.desktopImage) : this.desktopImage,
        imagesrcset: this.srcSetForImagePreload,
        imagesizes: '100vw',
      }],
    };
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_hero';
</style>
