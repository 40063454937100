<template>
  <div class="copyleaks-scan">
    <confirmation-dialog
      v-if="$store.state.currentUser.user.openConfirmationDialog"
      :heading="confirmationDialogHeading"
      confirm-text="Rescan"
      @confirm="rescanPost"
    />
    <copyleaks-scan-button
      ref="scanButton"
      :post-id="postId"
      :awaiting-webhook="!!scanInterval"
      :post-length="postLength"
      @pollForScanResults="beginPollingForScanResults"
    />
    <div
      v-if="scan && scan.scanStatus"
      class="copyleaks-scan__status"
    >
      Scan status: {{ scan.scanStatus }}
    </div>
    <div
      v-if="scan && scan.insertedAt"
      class="copyleaks-scan__timestamp"
    >
      Scan requested:
      <time-stamp
        v-if="scan && scan.insertedAt"
        :date="scan.insertedAt"
        format="dateAndTime"
      />
    </div>
    <loading-spinner v-if="loading" />
    <div v-else>
      <div v-if="!scan.scanId && !postIsTooShortToScan" class="copyleaks-scan__no-scan">
        <p> No scan found.  Try scanning a post. </p>
      </div>
      <div v-if="!scan.scanId && postIsTooShortToScan" class="copyleaks-scan__no-scan">
        <p>This post is too short to scan.  It must exceed {{ copyleaksScanWordMinimum }} words</p>
      </div>
      <ul v-else-if="scan.scanId && !postIsTooShortToScan" class="copyleaks-scan__details">
        <li>Scan Score: {{ scan.score }}%</li>
        <li>
          <checkbox-component
            id="is-flagged"
            :checked="isFlagged"
            class="copyleaks-scan__checkbox"
            value="isFlagged"
            :disabled="!isFlagged"
            @input="updateFlaggedStatus"
          >Is Flagged</checkbox-component>
        </li>
        <li v-if="showPdfUrl">
          <a :href="`${scan.pdfUrl}`" target="_blank"> PDF Report </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CopyleaksScanButton from '@/components/Content/Plagiarism/CopyleaksScanButton.vue';
import { GET_SCAN } from '@/graphql/queries/copyleaks-scan-queries';
import { UNFLAG_POST } from '@/graphql/mutations/copyleaks-scan-mutations';
import CheckboxComponent from '@/components/Inputs/CheckboxComponent.vue';
import TimeStamp from '@/components/Global/TimeStamp.vue';
import ConfirmationDialog from '@/components/User/ConfirmationDialog.vue';
import { config } from '@/configuration/nexus-config';

export default {
  name: 'CopyleaksScan',
  components: {
    CopyleaksScanButton,
    CheckboxComponent,
    TimeStamp,
    ConfirmationDialog,
  },
  props: {
    postId: {
      type: String,
      required: true,
    },
    postLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      scan: {},
      loading: false,
      scanInterval: null,
      isFlagged: false,
    };
  },
  computed: {
    copyleaksScanWordMinimum() {
      return config.copyleaksScanWordMinimum;
    },
    postIsTooShortToScan() {
      return this.postLength < this.copyleaksScanWordMinimum;
    },
    scanStatus() {
      return this.scan && this.scan.scanStatus;
    },
    showPdfUrl() {
      return this.scan && this.scan.scanStatus === 'PDF Uploaded';
    },
    confirmationDialogHeading() {
      return 'Are you sure you want to rescan this post?';
    },
  },
  beforeDestroy() {
    clearInterval(this.scanInterval);
  },
  methods: {
    rescanPost() {
      this.$store.dispatch('closeConfirmationDialog');
      clearInterval(this.scanInterval);
      this.scanInterval = null;
      this.$nextTick(() => {
        this.$refs.scanButton.tryToScanPost();
      });
    },
    updateFlaggedStatus({ checked }) {
      this.isFlagged = checked;
      if (!checked) {
        this.$apollo.mutate({
          mutation: UNFLAG_POST,
          variables: {
            scanId: this.scan.scanId,
          },
        }).then(() => {
          this.$store.dispatch('addToastNotification', {
            toastType: 'success',
            description: 'You have successfully unflagged this post.',
          });
        }).catch(() => {
          this.$store.dispatch('addGenericErrorNotification');
        });
      }
    },
    beginPollingForScanResults() {
      if (!this.scanInterval) {
        this.loading = true;
        this.$apollo.queries.getScan.refetch();
        this.scanInterval = window.setInterval(() => {
          this.$apollo.queries.getScan.refetch();
        }, 3000);
      }
    },
    allWebhooksReceived(status) {
      if (!status) { return false; }

      return status === 'Scan completed' || status === 'PDF Uploaded' || status.includes('Error');
    },
  },
  apollo: {
    getScan: {
      query: GET_SCAN,
      variables() {
        return {
          postId: this.postId,
        };
      },
      result({ data: { getScan } }) {
        if (!getScan) { return; }

        this.scan = getScan;

        if (this.allWebhooksReceived(getScan.scanStatus)) {
          clearInterval(this.scanInterval);
          this.scanInterval = null;
          this.isFlagged = getScan.isFlagged;
          this.loading = false;
          return;
        }

        if (getScan.scanStatus === 'Requested') {
          this.loading = true;
          this.beginPollingForScanResults();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_copyleaks-scan';

</style>
