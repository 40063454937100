<template>
  <div v-if="isReady" class="text-to-speech">
    <button class="text-to-speech__button button button--secondary" :disabled="isStarting" @click="click">
      <span v-if="isSpeaking">
        <icon-component
          name="pause"
          title="pause icon"
          class="text-to-speech__icon"
        />
        Listening
      </span>
      <span v-else>
        <icon-component
          name="play"
          title="play icon"
          class="text-to-speech__icon"
        />
        Listen
      </span>
    </button>
  </div>
</template>

<script>
import { isFeatureFlagEnabled } from '@/utils/utils';
import { mapGetters } from 'vuex';
// speechSynthesis can be buggy with long texts, that's why we split the text, and do
// pause/resume intervals below, otherwise it will stop before the text is finished being read.

export default {
  name: 'TextToSpeech',
  components: {
  },
  props: {
    htmls: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      acceptableVoices: ['Google US English', 'Samantha', 'Microsoft Aria Online (Natural) - English (United States)'],
      currentSection: 0,
      interval: null,
      isPaused: false,
      isSpeaking: false,
      isReady: false,
      isStarting: false,
      speaker: null,
      texts: [],
      textSplitLimit: 150,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
    ]),
    textToSpeechEnabled() {
      return isFeatureFlagEnabled(this.$site, 'text_to_speech');
    },
  },
  beforeDestroy() {
    this.destroy();
  },
  mounted() {
    if (process.browser && window && window.speechSynthesis) {
      if (!this.textToSpeechEnabled) { return; }
      window.speechSynthesis.cancel();
      const span = document.createElement('span');
      this.texts = this.htmls.map((h) => {
        span.innerHTML = h.replace('><', '> <').replace(/([^.]<\/h\d>)([^.])/g, '$1. $2');
        return this.splitLongText(span.textContent || span.innerText);
      }).flat(Infinity);
      this.speaker = new SpeechSynthesisUtterance(this.texts[0]);
      this.checkVoiceAndSetReady();
      window.onbeforeunload = () => {
        this.destroy();
      };
      if (!this.isReady) {
        window.speechSynthesis.onvoiceschanged = this.checkVoiceAndSetReady;
      }
    }
  },
  methods: {
    destroy() {
      clearInterval(this.interval);
      if (this.speaker) {
        this.speaker.onend = null;
      }
      if (window && window.speechSynthesis) {
        window.speechSynthesis.cancel();
      }
    },
    splitLongText(text) {
      const splitIndex = text.indexOf('. ', this.textSplitLimit);
      if (splitIndex > 0) {
        return [text.substring(0, splitIndex + 1), this.splitLongText(text.substring(splitIndex + 1))];
      }
      return text;
    },
    checkVoiceAndSetReady() {
      const voices = window.speechSynthesis.getVoices();
      this.speaker.voice = this.acceptableVoices.map((av) => voices.find((v) => v.name === av)).find((v) => !!v);
      if (this.speaker.voice) {
        this.speaker.onend = this.ended;
        this.speaker.onstart = this.started;
        this.isReady = true;
      }
    },
    started() {
      this.isStarting = false;
    },
    ended() {
      this.currentSection = this.currentSection + 1;
      if (this.currentSection >= this.texts.length) {
        clearInterval(this.interval);
        this.isSpeaking = false;
        window.speechSynthesis.cancel();
        this.currentSection = 0;
      } else {
        this.speaker.text = this.texts[this.currentSection];
        window.speechSynthesis.speak(this.speaker);
      }
    },
    preventLongTextBugs() {
      window.speechSynthesis.pause();
      window.speechSynthesis.resume();
    },
    click() {
      this.$snowplow.trackButtonEvent({
        data: { type: 'text to speech' },
      });
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { dialogText: 'Create an account or log in to listen.', initiator: 'text to speech' });
        return;
      }
      if (this.isSpeaking) {
        clearInterval(this.interval);
        window.speechSynthesis.pause();
        this.isSpeaking = false;
        this.isPaused = true;
      } else if (this.isPaused) {
        window.speechSynthesis.resume();
        this.interval = window.setInterval(this.preventLongTextBugs, 10000);
        this.isSpeaking = true;
        this.isPaused = false;
      } else {
        window.speechSynthesis.cancel();
        window.speechSynthesis.speak(this.speaker);
        this.interval = window.setInterval(this.preventLongTextBugs, 10000);
        this.isStarting = true;
        this.isSpeaking = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/components/_text-to-speech';
</style>

<docs>
A button to listen to text.
</docs>
