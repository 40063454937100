<template>
  <div class="nutrition-info">
    <div class="nutrition-info__section">
      <h2 class="nutrition-info__heading" data-test-id="nutrition-info-heading">Nutrition facts</h2>
      <p class="nutrition-info__serving">{{ nutritionData.serving || 'Per Serving' }}</p>
      <ul class="nutrition-info__list list-reset">
        <li
          v-for="(nutritionFact, key) in nutritionFacts"
          :key="key"
          class="nutrition-info__item"
          data-test-id="nutrition-info-list-item"
        >{{ formatKey(key) }}: {{ nutritionFact }}</li>
      </ul>
    </div>
    <div
      v-if="nutritionData.credits"
      class="nutrition-info__credits"
      data-test-id="nutrition-info-credits"
      v-html="nutritionData.credits"
    />
    <p class="nutrition-info__disclaimer disclaimer-text" data-test-id="nutrition-info-disclaimer-text">
      {{ defaultDisclaimer }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'NutritionInfo',
  props: {
    nutritionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultDisclaimer: `Disclaimer: ${this.$site.name} does not provide any express or implied warrant toward the content or outcome of any recipe.`,
    };
  },
  computed: {
    nutritionFacts() {
      const facts = { ...{}, ...this.nutritionData };
      const keysToRemoveFromTable = ['disclaimer', '__typename', 'credits', 'serving'];
      Object.keys(facts).forEach((key) => {
        if (keysToRemoveFromTable.includes(key) || facts[key] === null) {
          delete facts[key];
        }
      });

      return facts;
    },
  },
  methods: {
    formatKey(key) {
      return key.replace(/([A-Z])/g, ' $1').toLowerCase();
    },
  },
};
</script>

<docs>
Nutrition info table

</docs>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_nutrition-info';
</style>
