<template>
  <button-component
    type="submit"
    class="copyleaks-scan-button"
    button-type="scan for plagiarism"
    :disabled="requestedScan || postLength < copyleaksScanWordMinimum"
    @click.prevent="tryToScanPost"
  >{{ scanButtonText }}</button-component>
</template>

<script>
import ButtonComponent from '@/components/Global/ButtonComponent.vue';
import { SCAN_POST } from '@/graphql/mutations/copyleaks-scan-mutations';
import { config } from '@/configuration/nexus-config';

export default {
  name: 'CopyleaksScanButton',
  components: {
    ButtonComponent,
  },
  props: {
    postId: {
      type: String,
      required: true,
    },
    awaitingWebhook: {
      type: Boolean,
      required: true,
    },
    postLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      requestedScan: false,
    };
  },
  computed: {
    copyleaksScanWordMinimum() {
      return config.copyleaksScanWordMinimum;
    },
    scanButtonText() {
      if (this.awaitingWebhook) {
        return 'Scanning...';
      }
      return 'Scan Post';
    },
  },
  methods: {
    tryToScanPost() {
      this.requestedScan = true;

      if (this.awaitingWebhook) {
        this.$store.dispatch('openConfirmationDialog');
        this.requestedScan = false;
        return;
      }
      this.$apollo.mutate({
        mutation: SCAN_POST,
        variables: {
          postId: this.postId,
        },
      }).then(({ data: { scanPost } }) => {
        if (scanPost) {
          this.$emit('pollForScanResults');
        }
      }).catch(() => {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'Error communicating with Copyleaks API.  Please try again.',
        });
      }).finally(() => {
        this.requestedScan = false;
      });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_copyleaks-scan-button';
</style>
