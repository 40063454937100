<template>
  <div class="admin-toolbar">
    <div
      :class="`admin-toolbar__drawer ${isClosed ? 'admin-toolbar__drawer--closed' : ''}`"
      data-test-id="admin-toolbar-drawer"
    >
      <button
        class="admin-toolbar__toggle text-button"
        :aria-expanded="isClosed ? 'false' : 'true'"
        aria-controls="admin-toolbar__list"
        data-test-id="admin-toolbar-button"
        @click="toggleToolbar()"
      >
        {{ adminToolbarToggleText }}
      </button>
      <ul class="admin-toolbar__list">
        <li>
          <h3 class="admin-toolbar__heading">
            Post Details:
          </h3>
          <ul class="admin-toolbar__category">
            <li>
              <reindex-this-link
                v-if="hasPostId && isPublishedPost"
                :post-id="post.id"
              />
            </li>
            <li>
              <edit-this-link
                v-if="hasPostId"
                :post-id="post.id"
              />
            </li>
            <li>
              Total Ads: {{ postTotalAdCount }}
            </li>
            <li>
              Dynamic Ads: {{ postDynamicAdCount }}
            </li>
            <li>
              Word count: {{ postWordCount }}
            </li>
            <li>
              Last updated:
              <time-stamp
                v-if="lastUpdated"
                :date="lastUpdated"
                format="dateAndTime"
              />
            </li>
          </ul>
        </li>
        <li>
          <h3 class="admin-toolbar__heading">Plagiarism Check:</h3>
          <copyleaks-scan v-if="hasPostId" :post-id="post.id" :post-length="postWordCount" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ReindexThisLink from '@/components/Posts/ReindexThisLink.vue';
import EditThisLink from '@/components/Posts/EditThisLink.vue';
import TimeStamp from '@/components/Global/TimeStamp.vue';
import CopyleaksScan from '@/components/Content/Plagiarism/CopyleaksScan.vue';

export default {
  name: 'AdminToolbar',
  components: {
    ReindexThisLink,
    EditThisLink,
    TimeStamp,
    CopyleaksScan,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    dataLayer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  computed: {
    adminToolbarToggleText() {
      if (this.isClosed) {
        return 'Open Admin Toolbar';
      }
      return 'Close Admin Toolbar';
    },
    contentSections() {
      const noContent = !this.post || this.post.content === 'null';
      if (noContent) {
        return [];
      }
      return JSON.parse(this.post.content).sections;
    },
    postTotalAdCount() {
      return this.contentSections.reduce((acc, section) => {
        if (section.type === 'AdSlot') {
          return acc + 1;
        }
        return acc;
      }, 0);
    },
    postDynamicAdCount() {
      return this.contentSections.reduce((acc, section) => {
        if (section.type === 'AdSlot' && section.options && section.options.dynamic) {
          return acc + 1;
        }
        return acc;
      }, 0);
    },
    postWordCount() {
      return this.dataLayer.post_words || 0;
    },
    lastUpdated() {
      return this.dataLayer.post_modified;
    },
    hasPostId() {
      return !!this.post.id;
    },
    isPublishedPost() {
      return this.post.status === 'publish';
    },
  },
  created() {
    if (this.$route.query.preview === 'true') {
      this.isClosed = false;
    }
  },
  methods: {
    toggleToolbar() {
      this.isClosed = !this.isClosed;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_admin-toolbar';
</style>
