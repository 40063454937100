<template>
  <div :id="`comment-${comment.id}`" class="comment">
    <user-byline
      :user="comment.user"
      :date="comment.insertedAt"
      :show-date="true"
      :show-preposition="false"
    />
    <div class="comment__body">
      <component :is="bodyWithMentions" v-if="bodyWithMentions.template" />
      <p v-else>{{ body }}</p>
    </div>
    <div class="comment__engagement">
      <engagement-reply-bar
        object-type="comment"
        :comment="comment"
        :the-object="post"
        @delete="deleteComment"
        @submitted="handleReplySubmitted"
      />
    </div>
    <comment-replies
      v-if="hasReplies"
      ref="commentReplies"
      :comments="comment.comments"
      :post="post"
    />
  </div>
</template>

<script>
import UserByline from '@/components/User/UserByline.vue';
import EngagementReplyBar from '@/components/Engagement/EngagementReplyBar.vue';
import CommentReplies from '@/components/Comments/CommentReplies.vue';
import parseMentionsMixin from '@/mixins/parse-mentions-mixin';
import scrollToMixin from '@/mixins/scroll-to-mixin';
import { DELETE_COMMENT } from '@/graphql/mutations/comment-mutation';

export default {
  name: 'CommentComponent',
  components: {
    UserByline,
    EngagementReplyBar,
    CommentReplies,
  },
  mixins: [
    scrollToMixin,
    parseMentionsMixin,
  ],
  props: {
    comment: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasReplies() {
      return this.comment.comments && this.comment.comments.length > 0;
    },
    body() {
      // Remove unicode whitespace that surrounds mentions for IE11 and older Edge
      const regexp = new RegExp(/[\u2063]/, 'g');
      return this.comment.body.replace(regexp, '');
    },
  },
  methods: {
    deleteComment() {
      this.$apollo.mutate({
        mutation: DELETE_COMMENT,
        variables: {
          id: this.comment.id,
        },
      }).then(() => {
        this.$store.dispatch('addToastNotification', {
          toastType: 'success',
          description: 'Your comment was deleted',
          type: 'Comments',
        });
        this.$router.go();
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
    handleReplySubmitted(replyId) {
      this.$refs.commentReplies.showPreviousReplies();
      this.$nextTick(() => {
        this.scrollTo(`#comment-${replyId}`);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_comment';
</style>

<style lang="scss">
  .comment__body a:focus {
    @include highlighter-focus();
  }
</style>

<docs>
A single comment that displays a UserByline, a comment body, an engagement bar.
It may include a nested CommentReplies which receives comment.comments as its comments.

</docs>
