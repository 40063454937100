import gql from 'graphql-tag';

export const GET_SCAN = gql`
query getScan($postId: NexusId!) {
  getScan(postId: $postId){
   isFlagged
   pdfUrl
   postId
   scanId
   scanStatus
   score
   insertedAt
  }
}
`;
