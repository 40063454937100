import gql from 'graphql-tag';

export const SCAN_POST = gql`
  mutation scanPost($postId: NexusId!) {
    scanPost(postId: $postId)
  }
`;

export const UNFLAG_POST = gql`
  mutation unflagPost($scanId: String!) {
    unflagPost(scanId: $scanId) {
      isFlagged
    }
  }
`;
