import { render, staticRenderFns } from "./SocialShare.vue?vue&type=template&id=41e65366&scoped=true&"
import script from "./SocialShare.vue?vue&type=script&lang=js&"
export * from "./SocialShare.vue?vue&type=script&lang=js&"
import style0 from "./SocialShare.vue?vue&type=style&index=0&id=41e65366&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e65366",
  null
  
)

/* custom blocks */
import block0 from "./SocialShare.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports