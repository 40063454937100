<template>
  <div class="password-input">
    <text-input
      :id="id"
      :input-type="toggledInputType"
      :placeholder="placeholder"
      :value="value"
      @input="(value) => $emit('input', value)"
    >
      <slot>Password</slot>
    </text-input>
    <button
      class="password-input__visibility-button"
      type="button"
      @click="toggleVisibility"
    >{{ toggleButtonText }}</button>
  </div>
</template>

<script>
import TextInput from '@/components/Inputs/TextInput.vue';

export default {
  name: 'PasswordInput',
  components: {
    TextInput,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'password',
    },
  },
  data() {
    return {
      isVisible: false,
      toggledInputType: 'password',
      toggleButtonText: 'show',
    };
  },
  methods: {
    toggleVisibility() {
      if (this.isVisible) {
        this.isVisible = false;
        this.toggledInputType = 'password';
        this.toggleButtonText = 'show';
      } else {
        this.isVisible = true;
        this.toggledInputType = 'text';
        this.toggleButtonText = 'hide';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_password-input';
</style>

<docs>
</docs>
